import React, { useContext } from 'react';

import Accordion from '../../molecules/Accordion/Accordion';
import SimpleFilter from '../../molecules/SimpleFilter/SimpleFilter';
import Section from '../../molecules/Section/Section';

import AccordionSearch from './AccordionSearch/AccordionSearch';

import {
  sectionStyles,
  AccordionColumn,
  AccordionStyles,
  Content,
} from './AccordionStyles';

import { SimpleFilterProvider } from '../../contexts/simpleFilterContext';
import { FaqSearchContext } from '../../contexts/faqSearchContext';

const AccordionComponent = ({ component }) => {
  const {
    id,
    background,
    heading,
    subhead,
    kicker,
    accordions,
    includeFilters,
  } = component;
  const { faqSearchState } = useContext(FaqSearchContext);

  return (
    <Section style={sectionStyles(background)} id={id}>
      <AccordionStyles>
        <SimpleFilterProvider>
          <Content>
            {heading && <h2>{heading}</h2>}
            {subhead && <div dangerouslySetInnerHTML={{ __html: subhead }} />}
            {faqSearchState.search && (
              <AccordionSearch search={faqSearchState.search} />
            )}
            {includeFilters && !faqSearchState.search && (
              <SimpleFilter filters={accordions} />
            )}
          </Content>
          <AccordionColumn>
            <Accordion accordions={accordions} />
            {kicker && <div dangerouslySetInnerHTML={{ __html: kicker }} />}
          </AccordionColumn>
        </SimpleFilterProvider>
      </AccordionStyles>
    </Section>
  );
};

export default AccordionComponent;
